<template>
  <div class="mt-2">
    <campaign-charts-apps
      @filterReportByStatus="filterReportByStatus($event)"
    />
    <div v-if="false">
      <running-cards-apps class="mb-2" />
    </div>
    <v-row>
      <v-col cols="12">
        <h4>Latest Campaigns</h4>
      </v-col>
      <v-col cols="12">
        <ProgrammaticAppsTable @filterGraphByCampaign="filterGraphByCampaign($event)"
      @resetCampaignsFilter="resetCampaignsFilter($event)" />
      </v-col>
    </v-row>

    <!-- Banner approvals and submission -->
    <BannerSubmissions
      :dsp_creatives="dsp_creatives"
      :disapproved_creatives="disapproved_creatives"
      :unsubmitted_creatives="unsubmitted_creatives"
      @submitUnsubmittedBanner="handleSubmit($event)"
      @submitDisapprovedBanner="DisapprovedSubmit($event)"
      @submitDisapprovedMultiple="DisapprovedSubmitMultiple($event)"
      @submitUnsubmittedMultiple="UnsubmittedSubmitMultiple($event)"
      @triggerUnsubPageChange="readPaginatedUnsubmittedCreatives($event)"
      @triggerDisapprovedPageChange="readPaginatedDisapprovedCreatives($event)"
    />
  </div>
</template>

<script>

import CampaignChartsApps from "../../components/Home/CampaignChartsApps.vue";
import RunningCardsApps from "../../components/Home/RunningCardsApps.vue";
import BannerSubmissions from "../../components/banners.vue";
import ProgrammaticAppsTable from "../../components/Tables/ProgrammaticAppsTable.vue";


export default {
  components: {
    ProgrammaticAppsTable,
    CampaignChartsApps,
    RunningCardsApps,
    BannerSubmissions,
  },
  data: () => ({
    campaign_count: [],
    unsubmitted_id: "",
    totals_data: [],
    disapproved_banners: [],
    unsubmitted_banners: [],
    running_campaigns: [],
    offline_campaigns: [],
    filtered_campaigns: [],
    impressions_store: [],

    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
    // api: api,
    error: null,
    search: "",
    client: "",
    status: "Active",
    filterByCampaigns: []
  }),

  async mounted() {

    //Opera Campaign Stats
    await this.getAppCampaignsReports();

    //Unsubmitted Banners
    this.readPaginatedUnsubmittedCreatives();

    //Disaproved banners
    this.readPaginatedDisapprovedCreatives();

  },
  computed: {
    app_campaigns_statistics() {
      return this.$store.state.dsp.app_campaigns_statistics;
    },
    dsp_creatives() {
      return this.$store.state.dsp.dsp_creatives;
    },
    disapproved_creatives() {
      return this.$store.state.dsp.disapproved_creatives;
    },
    unsubmitted_creatives() {
      return this.$store.state.dsp.unsubmitted_creatives;
    },
  },
  watch: {
    opera_campaigns() {
      this.campaign_count = this.app_campaigns_statistics.Totalsopera;
    },
  },

  methods: {
    //filter graph data by campaign
    filterGraphByCampaign(campaigns) {
      this.filterByCampaigns = campaigns

      this.getAppCampaignsReports();
    },
    resetCampaignsFilter() {
      this.filterByCampaigns = []
      this.getAppCampaignsReports();
    },
    filterReportByStatus(status) {
      this.status = status;

      this.getAppCampaignsReports();
    },
    async getAppCampaignsReports() {
      let req_body = {
        app: "opera",
        body: {
          status_filter: this.status,
          group_by: "daily",
          campaigns: this.filterByCampaigns
        },
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getDSPAppsStats", req_body);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async handleSubmit(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitBanner", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Creative submitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the creative. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async DisapprovedSubmit(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/resubmitBanner", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Creative resubmitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem resubmitting the banner. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async DisapprovedSubmitMultiple() {
      const disapproved_banner_ids = this.disapproved_banners.map(
        (banner) => banner.banner_id
      );

      let body = {
        banner_ids: disapproved_banner_ids,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitManyBanners", body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Disapproved Creatives resubmitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem resubmitting the banners. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async UnsubmittedSubmitMultiple(ids) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/submitUnsubBanner", ids);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Unsubmitted Creatives submitted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the banners. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async readPaginatedUnsubmittedCreatives() {
      let req_body = {
        app: "opera",
      };

      // const page = value != undefined ? value : 1;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/getTargetUnsubmittedCreatives",
          req_body.app
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readPaginatedDisapprovedCreatives(value) {
      const page = value || 1;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAppsDisapprovedCreatives", page);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
